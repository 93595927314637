.fullscreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1021;
  background-color: var(--page-bg);
  overflow-y: auto;
  overflow-x: hidden;
  .close {
    z-index: 1022;
    position: sticky;
    top: 20px;
    right: 20px;
    float: right;
    cursor: pointer;
    font-size: 30px;
  }
}
.hidescroll {
  overflow-y: hidden;
}
.overlayScale {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: -webkit-transform 0.7s, opacity 0.7s, visibility 0s 0.7s;
  transition: transform 0.7s, opacity 0.7s, visibility 0s 0.7s;
}
.open {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.7s, opacity 0.7s;
  transition: transform 0.7s, opacity 0.7s;
}
.transactional {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.slidein {
  animation-duration: 1s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
