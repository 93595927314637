.request-home-container {
  padding-top: 62px;
  overflow-x: hidden;
  .Carousel_scrollable {
    padding-bottom: 0;
  }
  .requesthome-content {
    height: 100vh;
    width: 100vw;
    .requesthome-welcome {
      float: left;
      min-width: 32rem;
      padding: 54px 59px 36px 100px;
      overflow-x: hidden;
      overflow-y: scroll;
      width: calc(100vw - 30rem);
      .requesthome-header {
        position: relative;
        flex-direction: column;
        height: 19.0625rem;
        border-radius: 16px;
        border: solid 1px var(--light-border);
        margin-bottom: 42px;
        display: flex;
        padding: 28px 15px 14px 10px;
        min-width: 45.9375rem;
        .illustration {
          position: absolute;
          top: 18px;
          right: 50px;
        }
        .carosal-cards {
          margin-right: 20px;
          border-left: solid 1px var(--light-border);
          padding-left: 20px;
        }
        .carosal-cards.withourborder {
          border-left: none;
        }
        .welcometext-part {
          min-height: 3.4375rem;
          padding-right: 30px;
          padding-left: 21px;
          .home-priorityactions {
            font-size: 14px;
            color: var(--home-subtitle);
          }
          .request-hometitle {
            font-size: 14px;
            color: var(--home-subtitle);
            font-weight: normal;
          }
        }
        .overview {
          margin-top: 1.687rem;
          .card-overview {
            visibility: hidden;
            font-size: 14px;
            color: var(--home-main-title);
            padding-left: 8px;
            padding-top: 10px;
          }
        }
      }
      .request-welcome-text {
        font-size: 14px;
        color: var(--grey-font-input);
        font-weight: normal;
      }
      .request-welcome-user {
        font-size: 20px;
        font-weight: 500;
        color: var(--home-main-title);
      }

      .request-section-title {
        font-size: 11px;
        font-weight: normal;
        color: var(--grey-font-input);
        padding-left: 26px;
        text-transform: uppercase;
      }

      .request-actions {
        width: 23vw;
      }

      .important-actions {
        margin-bottom: -48px;
        .content-title-section {
          padding-bottom: 4px;
          .action-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            color: #414150;
            width: 223px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .action-img {
            display: block;
            width: 43px;
            height: 43px;
            border-radius: 100%;
            margin-left: 39px;
            position: relative;
            img {
              z-index: 10;
              position: relative;
              border-radius: 100%;
            }
            .app-initial {
              position: absolute;
              top: 14px;
              text-align: center;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
              z-index: 1;
            }
            &.img-error {
              background-color: var(--grey-border);
              img {
                display: none;
              }
            }
          }
        }
        .action-dot {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 6px;
        }

        .action-dot.Approved {
          background-color: #1cb26d;
        }

        .action-dot.Expired,
        .action-dot.Expiring {
          background-color: var(--failure-red);
        }

        .action-status {
          font-size: 12px;
          font-weight: 500;
          border-radius: 6px;
          display: inline;
          padding: 5px 14px;
        }
        .action-status.Approved {
          color: #1cb26d;
        }

        .action-status.Expiring,
        .action-status.Expired {
          color: var(--failure-red);
        }
        .action-footer {
          padding-top: 30px;
          .footer-content {
            display: inline-block;
            float: right;
            .label-name {
              font-size: 11px;
              color: var(--grey-font-input);
              padding-bottom: 4px;
              padding-top: 3px;
              font-weight: normal;
            }
            .label-value {
              font-size: 12px;
              color: #000000;
              font-weight: normal;
            }
          }
        }
      }
      .dummykpilayout {
        .dummy {
          max-width: 15rem;
          min-width: 15rem;
          margin-right: 15px;
        }
        .loaderdummy {
          display: flex;
          padding: 10px 10px;
          /* padding-bottom: 0; */
          flex-direction: column;
          justify-content: center;
        }
      }
      .request-feature {
        padding-right: 0px;
        .request-section-cards {
          font-size: 14px;
          color: var(--home-featuretile-title);
          margin-bottom: 24px;
          // margin-left: 8px;
        }
        .reqeust-cards {
          margin-left: -6px;
          margin-right: -15px;
        }
        .feature-content {
          cursor: pointer;
          width: 100%;
          height: 9.6875rem;
          border-radius: 16px;
          padding: 16px 16px 21px;
          background-color: var(--featuretile-Bg);
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          &:hover {
            transform: scale(1.02);
          }
        }
        .dummy {
          max-width: 15rem;
          min-width: 15rem;
          margin-right: 15px;
        }
        .loaderdummy {
          display: flex;
          padding: 10px 10px;
          /* padding-bottom: 0; */
          flex-direction: column;
          justify-content: center;
        }
        .tile-width {
          width: 15rem;
          max-width: 15rem;
          min-width: 15rem;
          padding-left: 2px;
          margin-right: 10px;
        }
        .request_new_access {
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
          border-radius: 16px;
          // border: solid 1px #5fb8ff;
          // background-image: linear-gradient(133deg, #6096ff -16%, #2cf2ff 110%);
        }
        .request_history {
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
          border-radius: 16px;

          // border: solid 1px #ff6746;
          // background-image: linear-gradient(132deg, #ff8449 -7%, #ff80d2 131%);
        }
        .manage_access_review {
          // background-image: linear-gradient(138deg, #006ce0 0%, #7fbfff 100%);
          // border: 1px solid #23ca88;
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
          border-radius: 16px;
        }
        .manage_access_review_team {
          background-image: linear-gradient(138deg, #006ce0 0%, #7fbfff 100%);
          border: 1px solid #23ca88;
          box-shadow: 3px 3px 8px 0 #dfe0eb;
        }

        .privileged_access,
        .access_review {
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
          border-radius: 16px;

          // border: solid 1px #ff972b;
          // background-image: linear-gradient(131deg, #ff9a48 -31%, #eec649 111%);
        }

        .accounts {
          // background-image: linear-gradient(138deg, #28a745 20%, #28a745 50%);
          // border: 1px solid #28a745;
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
          border-radius: 16px;
        }

        .manage_ad_groups {
          // background-image: linear-gradient(135deg, #009db4 0%, #79d0c5 100%);
          // border: 1px solid #239ba7;
          border-radius: 16px;
          box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
          background-color: #ffffff;
        }
        .feature-img {
          display: flex;
          margin-right: auto;
          border-radius: 12px;
          position: relative;
          margin-bottom: 10px;
          margin-top: 3px;
          width: 40px;
          height: 40px;
          padding: 8px;
          i {
            margin-left: -5px;
          }
          svg {
            fill: var(--primary-main) !important;
            transform: scale(1.4);
            g {
              fill: var(--primary-main) !important;
            }
          }
        }
        .feature-title {
          font-weight: 500;
          line-height: 1.4;
          text-align: left;
          color: var(--home-featuretile-title);
          font-size: 14px;
          margin-bottom: 0;
        }
        .feature-description {
          font-size: 14px;
          line-height: 1.29;
          text-align: left;
          color: var(--home-subtitle);
          padding-top: 4px;
          font-weight: normal;
        }
      }
    }
    .requesthome-container {
      position: fixed;
      right: 0;
      background-color: #ffffff;
      width: 32%;
      overflow: scroll;
      height: 100%;
      padding-bottom: 40px;
      overflow-x: hidden;
      max-width: 30rem;
    }
    .requesthome-activity {
      // box-shadow: 3px 3px 8px 0 var(--primary-bg);
      // background-color: var(--dark-bg);
      padding-bottom: 50px;
      background-color: #ffffff;
      height: 100%;
      .pending-action-layout {
        padding: 5px 17px 5px 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .lastpageimage {
          margin-top: 20px;
          margin-bottom: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .viewall {
          margin-left: 20px;
          margin-right: 20px;
        }
        .lastpagemessage {
          margin-top: -70px;
        }
        .pendingemptyrequest {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 20px;
          .pendingmessage {
            margin-top: 25px;
            font-size: 14px;
            color: var(--grey-font);
          }
        }
        .toggleLayout {
          padding-left: 20px;
        }
        .requestedfor {
          color: var(--home-main-title);
        }
        .certifierlabel {
          color: var(--home-subtitle) !important;
          max-width: 15.625rem;
        }
        .wauto {
          width: auto !important;
        }

        .review-btn {
          visibility: hidden;
          align-self: flex-end !important;
        }
        .recent-notification {
          margin-top: 15px;
          .pendingkey {
            margin-left: 20px;
            margin-bottom: 5px;
            margin-top: 5px;
            color: var(--home-subtitle);
            font-size: 12px;
            text-transform: uppercase;
          }
          .notification-desc {
            color: var(--home-main-title);
            margin-bottom: 3px !important;
            font-size: 12px;
            line-height: 1.33;
          }
        }

        .notification-content {
          &:hover {
            .review-btn {
              align-self: flex-end !important;
              visibility: visible;
            }
          }
        }
        .riskicon {
          background-color: #31b793;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          visibility: visible;
          margin-left: 5px;
        }
        .Medium {
          background-color: #ff9200;
        }
        .Low {
          background-color: #ffe567;
          color: #ffe567;
        }
        .High {
          background-color: #e7291b;
        }
        .MediumText {
          color: #ff9200;
        }
        .LowText {
          color: #ffe567;
        }
        .HighText {
          color: #e7291b;
        }
      }
      .recent-activity {
        padding-top: 23px;
        background-color: var(--home-rightpanel-bg);
        margin-bottom: 17px;
        padding-bottom: 1px;
        z-index: 999;
        .activity-title {
          font-size: 18px;
          font-weight: 500;
          color: var(--black-li);
        }
        // .MuiTab-textColorPrimary.Mui-selected {
        //   .MuiTab-wrapper {
        //     color: black !important;
        //   }
        // }
      }

      .recent-announcement {
        padding: 18px 36px 24px;
        border-top: 1px solid #414150;
        background-color: var(--dark-bg-li);

        .announcement-title {
          .title {
            font-size: 12px;
            color: var(--grey-font-input);
            text-transform: uppercase;
          }
          .just-now {
            font-size: 12px;
            line-height: 1.33;
            color: #7c7c8b;
          }
        }

        .announcement-content {
          padding-top: 20px;
          .announcement-icon {
            div {
              width: 44px;
              height: 44px;
              background-color: #414150;
              border-radius: 100%;
              position: relative;

              img {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                right: 0;
                width: 34px;
              }
            }
          }
          .announcement-description {
            font-size: 14px;
            line-height: 1.5;
            color: #ffffff;
            padding-right: 19px;
            padding-left: 15px;
          }
        }
      }
      .recent-notification {
        margin-bottom: 70px;
        .title {
          font-size: 12px;
          color: #6d6d7c;
          text-transform: uppercase;
          padding: 18px 36px 8px;
          font-weight: normal;
        }
        .content-pending {
          &:hover {
            background-color: var(--hover-Bg);
          }
        }
        .notification-content {
          padding: 14px 18px;
          margin: 0 16px;
          border-radius: 6px;
          cursor: default;
          outline: none;
          .review-btn {
            align-self: center;
            z-index: 1;
          }
          &:hover {
            //background-color: var(--hover-Bg);
            .risk-text {
              visibility: visible !important;
            }
            .review-btn {
              visibility: visible !important;
              align-self: center;
            }
            .notification-icon {
              .iconpending {
                background-color: var(--primary-main) !important;
                fill: #ffffff !important;
              }
            }
          }
        }
        .notification-icon.col-2 {
          flex: 0 0 13.666667%;
          max-width: 13.666667%;
        }

        .notification-icon {
          .iconpending {
            background-color: var(--pendingaction-icon-bg) !important;
            fill: var(--primary-main) !important;
          }
          .item-img {
            width: 44px;
            height: 44px;
            position: relative;
            background-color: transparent;
            .icon {
              position: relative;
              width: 44px;
              height: 44px;
              background-color: white;
              border-radius: 100%;
            }
            .icon-recent {
              width: 36px;
              height: 36px;
              margin: 2px 0px 0px 2px;
              position: absolute;
              z-index: 2;
            }
            .erproleicon {
              background-color: var(--recentAct-icon-bg);
              border-radius: 50%;
              width: 36px;
              height: 36px;
            }
            .app-initial {
              background-color: var(--recentAct-icon-bg);
              color: white;
              border-radius: 50%;
              margin-bottom: 0;
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 2px;
              position: absolute;
              top: 0px;
              margin-left: 2px;
              margin-top: 2px;
            }
          }
          .icon {
            width: 36px;
            height: 36px;
            background-color: #414150;
            border-radius: 100%;
            position: relative;
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
              right: 0;
              width: 100%;
              height: 34px;
            }
          }
        }

        .content-section {
          // padding-left: 15px;
          margin-left: 0px;
          width: 313px;
          .notification-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: var(--home-black-dark);
            padding-bottom: 6px;
            word-break: break-word;
          }

          .notification-description {
            font-size: 14px;
            line-height: 1.33;
            color: var(--home-subtitle);
            width: 275px;
          }
        }
        .risk-section {
          .review-btn {
            visibility: hidden;
            align-self: center;
          }
          .risk-type {
            font-size: 10px;
            .risk-text {
              font-size: 10px;
              font-weight: 500;
              line-height: 1.6;
              text-transform: uppercase;
              margin-right: 5px;
              visibility: hidden;
              width: 40px;
              position: relative;
              top: 0.5px;
            }
            .Medium {
              color: #ff9200;
            }
            .Low {
              color: #ffe567;
            }
            .High {
              color: #e7291b;
            }
            .risk-icon {
              width: 6px;
              height: 6px;
              background-color: #ff9200;
              border-radius: 100%;
              display: inline-block;
              top: 1px;
              position: relative;
            }

            .risk-icon.Medium {
              background-color: #ff9200;
            }
            .risk-icon.Low {
              background-color: #ffe567;
            }
            .risk-icon.High {
              background-color: #e7291b;
            }
          }
        }
      }
    }
    // https://benfrain.com/playground/scroll-test.html

    // https://benfrain.com/independent-scrolling-panels-body-scroll-using-just-css/

    .disable-scrollbars {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px !important;
        background: transparent; /* Chrome/Safari/Webkit */
      }
    }
    .home-full-width {
      max-width: 100vw;
      width: 100%;
    }
  }
}

//.s-footer {
//  display: none;
//}

.actionLoader {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;

  .subTitleLoader {
    width: 90px;
    height: 20px;
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
  }
}

.loaderCss {
  display: flex;
  padding: 30px 40px;
  padding-bottom: 0;
  .loadin-image {
    width: 20px !important;
    height: 36px !important;
    margin-left: 77px;
  }
  .requestAction-Tiles {
    .status-Tile-Data {
      padding-top: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 10px;
    }
    .status-Tile {
      height: 65px !important ;
      width: 50% !important ;
    }
    .dataLoading {
      .status-count {
        margin-bottom: auto !important;
        margin-top: 10px;
        /* width: 50%; */
        height: 18px;
      }
    }
    margin-left: 0px !important;
    &:hover {
      background-color: #ffffff;
      cursor: default;
    }
  }
  .imgIcondummy {
    width: 30px !important;
    height: 30px !important;
  }
  .imgIcon {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
  }
  .dataLoadingdummy {
    margin-left: 0px !important;
  }
  .dummywidth {
    min-width: 200px;
  }
  .dataLoading {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    width: 100%;
    padding-top: 10px;

    .gridLoading {
      display: block;
      width: 90%;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.15);
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      background-size: 500px 100px;
      animation-name: moving-gradient;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      position: relative;
      border-radius: 4px;
    }
  }
}

.gridLoading {
  width: 60%;
}

@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

.featureloader {
  width: 230px;
  height: 155px;
  border-radius: 16px;
  box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
  background-color: #ffffff;
  padding-top: 10px;
  padding-right: 10px;
  margin-left: 20px;

  .imgIcon {
    width: 40px;
    height: 40px;
    margin-top: 15px;
    margin-left: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
  }

  .dataLoading {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    width: 100%;
    padding-top: 10px;

    .gridLoading {
      display: block;
      width: 90%;
      height: 18px;
      background-color: rgba(0, 0, 0, 0.15);
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      background-size: 500px 100px;
      animation-name: moving-gradient;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      position: relative;
      border-radius: 4px;
    }
  }
}

.headerDataLoading {
  .userdataLoad {
    display: block;
    width: 80%;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
    border-radius: 4px;
  }
  .userAction {
    width: 40%;
  }
  .numberActions {
    width: 50%;
  }
}

.actionLoading {
  height: 158px;
  width: 298px;
  .actiondataHead {
    display: block;
    width: 40%;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
    border-radius: 4px;
  }
  .actiondata {
    display: block;
    width: 90%;
    height: 18px;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
    border-radius: 4px;
    .actionTitle {
      width: 40%;
    }
  }

  .slideButton {
    width: 80px;
    display: flex;
    .slideLoader {
      display: block;
      width: 50%;
      height: 25px;
      background-color: rgba(0, 0, 0, 0.15);
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      background-size: 500px 100px;
      animation-name: moving-gradient;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      position: relative;
      border-radius: 4px;
    }
  }
  .accessAction {
    width: 40%;
    height: 30px;
  }

  .imgIcon {
    width: 40px;
    height: 40px;
    margin-top: 15px;
    margin-left: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
  }
}

.pendingtaskonly {
  width: 100%;
  min-width: 480px;
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(247, 248, 252, 1);
  opacity: 1;
}
a {
  text-decoration: none;
}
div {
  -webkit-text-size-adjust: none;
}
.anima-full-width-a {
  width: 100%;
}
.anima-full-height-a {
  height: 100%;
}
div {
  -webkit-text-size-adjust: none;
}
.pendingtaskonly .pendingtasks {
  background-color: white;
  top: -5px;
  height: 1023px;
  width: 496px;
  position: relative;
  margin: 0;
  left: -5px;
  transform: rotate(0deg);
  opacity: 1;
  margin-top: -140px;
}
.pendingtaskonly .pendingtasks .box {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 1023px;
  width: 496px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 {
  background-color: rgba(255, 255, 255, 0);
  top: 530px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .u77e9u5f62u5907u4efd10 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .roleowner {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .giovannicert {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(60, 60, 60, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .path {
  background-color: rgba(255, 255, 255, 0);
  top: 33px;
  height: 1px;
  width: 1px;
  position: absolute;
  margin: 0;
  left: 352px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .certifierrh00009g {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .certifierrh00009g .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .certifierrh00009g .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .combinedshapeu5907u4efd {
  background-color: rgba(255, 255, 255, 0);
  top: 25px;
  height: 19px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec437 .u692du5706u5f62u5907u4efd3 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 {
  background-color: rgba(255, 255, 255, 0);
  top: 700px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .u77e9u5f62u5907u4efd12 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .usermanagerq12020 {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(46, 46, 46, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .combinedshapeu5907u4efd2 {
  background-color: rgba(255, 255, 255, 0);
  top: 25px;
  height: 19px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .selfcertification1 {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .certifieradmindan {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .certifieradmindan .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .certifieradmindan .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec441 .u692du5706u5f62u5907u4efd6 {
  background-color: rgba(255, 255, 255, 0);
  top: 22px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 {
  background-color: rgba(255, 255, 255, 0);
  top: 614px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .u77e9u5f62u5907u4efd11 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .a2260053 {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .privilegeidrequest {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(46, 46, 46, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestfordavidsm {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestfordavidsm .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestfordavidsm .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestu5907u4efd4 {
  background-color: rgba(255, 255, 255, 0);
  top: 26px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestu5907u4efd4 .group2 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: relative;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .requestu5907u4efd4 .group2 .combinedshape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec438 .u692du5706u5f62u5907u4efd4 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 {
  background-color: rgba(255, 255, 255, 0);
  top: 698px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .u77e9u5f62u5907u4efd12 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .a2280170 {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .newaccountrequest {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(46, 46, 46, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestforchrisra {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestforchrisra .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestforchrisra .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestu5907u4efd5 {
  background-color: rgba(255, 255, 255, 0);
  top: 26px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestu5907u4efd5 .group2 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: relative;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .requestu5907u4efd5 .group2 .combinedshape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec440 .u692du5706u5f62u5907u4efd5 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 {
  background-color: rgba(255, 255, 255, 0);
  top: 157px;
  height: 30px;
  width: 207px;
  position: absolute;
  margin: 0;
  left: 41px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .searchiconbodyrectcopy2 {
  background-color: rgba(255, 255, 255, 0.5);
  top: 0px;
  height: 30px;
  box-sizing: border-box;
  width: 67px;
  position: absolute;
  margin: 0;
  left: 140px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 224, 235, 1);
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .searchiconbodyrectcopy4 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 30px;
  box-sizing: border-box;
  width: 91px;
  position: absolute;
  margin: 0;
  left: 41px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 224, 235, 1);
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .searchiconbodyrectcopy4u5907u4efd {
  background-color: rgba(235, 238, 255, 1);
  top: 0px;
  height: 30px;
  width: 33px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 3px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .request {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 149px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .certification {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 50px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec432 .all {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 9px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: rgba(55, 81, 255, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .group4u5907u4efd3 {
  background-color: rgba(255, 255, 255, 0);
  top: 157px;
  height: 30px;
  width: 30px;
  position: absolute;
  margin: 0;
  left: 412px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .group4u5907u4efd3 .grey {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 30px;
  box-sizing: border-box;
  width: 30px;
  position: relative;
  margin: 0;
  left: 0px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(221, 221, 221, 1);
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec412 {
  background-color: rgba(255, 255, 255, 0);
  top: 165px;
  height: 14px;
  width: 15px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec412 .u8defu5f84 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 14px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec412 .u8defu5f84u5907u4efd {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 14px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 9px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .today {
  background-color: rgba(255, 255, 255, 0);
  top: 211px;
  height: auto;
  width: 338px;
  position: absolute;
  margin: 0;
  left: 42px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  text-align: left;
  line-height: 21px;
}
.pendingtaskonly .pendingtasks .lastsevendays {
  background-color: rgba(255, 255, 255, 0);
  top: 499px;
  height: auto;
  width: 338px;
  position: absolute;
  margin: 0;
  left: 42px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(163, 163, 163, 1);
  text-align: left;
  line-height: 21px;
}
.pendingtaskonly .pendingtasks .recentactivity {
  background-color: rgba(255, 255, 255, 0);
  top: 97px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 41px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  letter-spacing: 0.1px;
  line-height: 19px;
}
.pendingtaskonly .pendingtasks .pendingtasks1 {
  background-color: rgba(255, 255, 255, 0);
  top: 97px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 198px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 0.1px;
  line-height: 19px;
}
.pendingtaskonly .pendingtasks .lineu5907u4efd2 {
  background-color: rgba(255, 255, 255, 0);
  top: 132px;
  height: 1px;
  width: 481px;
  position: absolute;
  margin: 0;
  left: 4px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u77e9u5f62 {
  background-color: rgba(255, 255, 255, 0);
  top: 130px;
  height: 3px;
  width: 121px;
  position: absolute;
  margin: 0;
  left: 194px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 {
  background-color: rgba(255, 255, 255, 0);
  top: 239px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .u77e9u5f62u5907u4efd7 {
  background-color: rgba(244, 246, 250, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .a37517780 {
  background-color: rgba(255, 255, 255, 0);
  top: 37px;
  height: auto;
  width: 62px;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .newaccountrequest {
  background-color: rgba(255, 255, 255, 0);
  top: 17px;
  height: auto;
  width: 214px;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestforjakesmi {
  background-color: rgba(255, 255, 255, 0);
  top: 55px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestforjakesmi .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestforjakesmi .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .u77e9u5f621 {
  background-color: rgba(255, 255, 255, 1);
  top: 40px;
  height: 28px;
  box-sizing: border-box;
  width: 78px;
  position: absolute;
  margin: 0;
  left: 348px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(55, 81, 255, 1);
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .viewdetail {
  background-color: rgba(255, 255, 255, 0);
  top: 47px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 355px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: rgba(55, 81, 255, 1);
  text-align: left;
  line-height: 13px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .risky {
  background-color: rgba(255, 255, 255, 0);
  top: 17px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 382px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: rgba(240, 106, 96, 1);
  text-align: right;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestu5907u4efd6 {
  background-color: rgba(255, 255, 255, 0);
  top: 26px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestu5907u4efd6 .group2 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: relative;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .requestu5907u4efd6 .group2 .combinedshape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec434 .u692du5706u5f62u5907u4efd {
  background-color: rgba(255, 255, 255, 0);
  top: 21px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 {
  background-color: rgba(255, 255, 255, 0);
  top: 323px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .u77e9u5f62u5907u4efd8 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .usermanager {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .jrmtestjan {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(41, 41, 41, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .certifieradmindan {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .certifieradmindan .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .certifieradmindan .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .combinedshape {
  background-color: rgba(255, 255, 255, 0);
  top: 25px;
  height: 19px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec435 .u692du5706u5f62 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 {
  background-color: rgba(255, 255, 255, 0);
  top: 407px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 20px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .u77e9u5f62u5907u4efd9 {
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  height: 84px;
  width: 448px;
  position: absolute;
  margin: 0;
  left: 0px;
  border-radius: 6px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .oval {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 22px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .a2260157 {
  background-color: rgba(255, 255, 255, 0);
  top: 36px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .privilegeidrequest {
  background-color: rgba(255, 255, 255, 0);
  top: 16px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(36, 36, 36, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestfordavidsm {
  background-color: rgba(255, 255, 255, 0);
  top: 54px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 74px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Rubik', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-align: left;
  line-height: 14px;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestfordavidsm .span1 {
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestfordavidsm .span2 {
  color: rgba(0, 0, 0, 1);
  text-align: justify;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestu5907u4efd3 {
  background-color: rgba(255, 255, 255, 0);
  top: 26px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 32px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestu5907u4efd3 .group2 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: relative;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .requestu5907u4efd3 .group2 .combinedshape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 17px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 0px;
  transform: rotate(0deg);
  opacity: 1;
}
.pendingtaskonly .pendingtasks .u7f16u7ec436 .u692du5706u5f62u5907u4efd2 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 6px;
  width: 6px;
  position: absolute;
  margin: 0;
  left: 420px;
  transform: rotate(0deg);
  opacity: 1;
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity: 0;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity: 1;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}
@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.anima-component-wrapper,
.anima-component-wrapper * {
  pointer-events: none;
}
.anima-component-wrapper a *,
.anima-component-wrapper a,
.anima-component-wrapper input,
.anima-component-wrapper video,
.anima-component-wrapper iframe,
.anima-listeners-active,
.anima-listeners-active * {
  pointer-events: auto;
}
.anima-hidden,
.anima-hidden * {
  visibility: hidden;
  pointer-events: none;
}
.anima-smart-layers-pointers,
.anima-smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}
.anima-component-wrapper.anima-not-ready,
.anima-component-wrapper.anima-not-ready * {
  visibility: hidden !important;
}
.anima-listeners-active-click,
.anima-listeners-active-click * {
  cursor: pointer;
}
.anima-word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .tile-width {
    min-width: 14 rem !important;
    max-width: 14 rem !important;
  }
}
