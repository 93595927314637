.iconHover {
  outline: none !important;
  &:hover {
    span {
      svg {
        fill: var(--primary-main);
      }
    }
  }
}


