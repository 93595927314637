.pagination-scroll-dropdown {
  width: 100%;
  position: relative;
  .selected-options {
    padding-bottom: 5px;
    display: inline;
  }
  .formlayout{
    display:flex;
  }
  .dropdownicon{
    margin-left: -40px;
    z-index: 1;
    margin-bottom: -15px;
  }
}