/* =============Footer CSS============== */
.s-footer {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 8px 20px 5px 20px;
}
.left-align{
max-width:  68%;
}
.s-footer .s-footer__copyright {
  font-size: 12px;
  font-weight: 400;
  color: #96a2ab;
}
.footer-layout {
  position: relative;
}
