.list_view_container {
  a {
    display: inline-block;
    // background-color: #b4b4b4;
    margin-left: 13px;
    border-radius: 50%;
    margin-right: 21px;
  }

  .content {
    height: 71px;
    background-color: #ffffff;
    border: solid 1px var(--grey-border);
    padding: 12px 20px 12px 11px;
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &:not(:last-child) {
      border-bottom: none;
    }

    .list-content {
      .icon-size {
        border-radius: 50%;
        background-color: var(--grey-border);
        display: table-cell;

        .connector {
          height: 25px;
          width: 25px;
          margin: 10px;
        }
      }

      .customCheck {
        margin-top: 10px;
      }

      .customCheck input[type='checkbox'] + label {
        margin-top: 5px;
        visibility: hidden;
      }

      .text-color {
        color: var(--grey-font);
        // font-size: 12px;
        margin: 0 5px;
      }

      .dot {
        height: 4px;
        width: 4px;
        background-color: var(--grey-font);
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: 4px;
      }

      .sub-header {
        line-height: 1.27;
        letter-spacing: 0.4px;
        font-size: 11px;
      }

      .text-content {
        font-size: 15px;
        font-weight: 500;
      }

      .sub-content {
        // font-size: 12px;
        color: #000000;
        margin-right: 5px;
      }

      .list-inline-view {
        display: flex;
        .future-text {
          min-width: 85px;
          margin-right: 24px;
        }
        .label-left {
          color: var(--grey-font);
          font-size: 14px;
          margin-left: 5px;
        }

        .btn-access {
          width: 142px;
          height: 44px;
          font-size: 12px;
          font-weight: 500;
          padding-top: 5px;
          margin: 0;
          align-self: center;
          margin-top: 0 !important;
        }

        .more-options {
          display: flex;
          align-items: center;
          .btnbox {
            background-color: #ffffff;
            position: absolute;
            right: -65px;
            top: 30px;

            .btn-ingrid {
              border: none !important;
              height: 26px;
              font-size: 0.67rem;
              text-align: left;
              padding: 0 0 0 7px;
            }

            .btn-ingrid:hover {
              color: var(--primary-main);
              background-color: #ffffff;
            }
          }

          &:hover .btnbox {
            display: flex;
            flex-direction: column;
            box-shadow: 0.5px 0.5px 2px 0 #bfc0ca;

            .btn-outline-primary {
              line-height: 1.5;
              color: #b4b4b4;
              font-weight: 500;
              font-size: 12px;
              padding: 5px 7px;
            }

            .btn-outline-primary:hover,
            .btn-outline-primary:focus {
              color: var(--primary-main);
            }
          }
        }
      }
    }

    .list-content:nth-child(3) {
      margin-left: auto !important;
    }

    .customCheck input[type='checkbox']:checked + label {
      display: block;
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover .customCheck input[type='checkbox'] + label {
      display: block;
      visibility: visible;
    }
  }
}
