.extendSessionDialog{
    background-color: var(--page-bg);
    width: 488px;
    height: 489px;
}
.cal{
    display:flex;
}
.sessionBadge{
    border-radius: 11px;
  background-color: #eeeeee;
  max-width: 88px;
  padding-left: 8px;
  margin-bottom: 25px;

}

.fontSelection{
    font-size: 10px;
    color: #000000;
}

.extendText{
    font-size: 12px;
    color: var(--primary-main);
    font-weight: 500;
    &::after{
        content:"*";
color: red;

    }
}
.extendTextArea{
    border-radius: 3px;
    border: solid 1px var(--primary-bg);
    padding: 8px;
    resize: none;
    margin-top: 10px;

}

::placeholder{
    font-size: 12px;
    color: var(--primary-bg);
}

.titleText{
    font-size: 14px;
    color: #000000;
}

.extendContent{
    padding: 0 25px 16px 19px;
}

.extendTitle{
    padding-bottom: 2px;
    padding-left: 19px;
}

.extendClose{
    border-radius: 3px;
    border: solid 1px var(--primary-bg);
    color: var(--primary-bg);
    padding: 5px;
    font-size:  10px;
    &:hover{
        color: #000000;
    }
}

.extendFooter{
    padding-right: 26px;
    padding-bottom: 30px;
}

.extendRequest{
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 10px;
    padding: 5px;

}
.closeextend{
    border-radius: 3px;
    border: solid 1px var(--primary-bg);
    color: var(--primary-bg);
    &:hover{
        color: #000000;
    }
}
.extendDate {
    margin-bottom: 0;
}
.extendDateError {
    color: var(--failure-red);
    font-size: 12px;
}