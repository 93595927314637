.card_view_sessions {
  .card-columns {
    column-count: 5 !important;
  }
  .dot {
    height: 4px;
    width: 4px;
    background-color: var(--grey-font);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 4px;
  }
  .card-view {
    width: 20%;
    .card {
      width: 206px;
      height: 230px;
      border: none;
      box-shadow: 1px 1px 5px 0 rgba(205, 206, 217, 0.5);
      .card-header {
        background-color: white;
        margin: 0 10px;
        height: 38px;
        margin-top: 1px;
        .customCheck {
          width: 100%;
        }
        .customCheck input[type='checkbox'] + label {
          display: none;
        }
        .customCheck input[type='checkbox']:checked + .more-options {
          display: block;
        }
        .connector {
          .cl-logo {
            height: 20px;
          }
          div{
            height: 20px;
            max-width: 20px;
            min-width: 20px;
            margin-top:0;
          }
        }
        .more-options {
          display: none;
          right: 15px;
          position: absolute;
          .more-listview {
            color: #b4b4b4;
            &:hover {
              color: var(--primary-main);
            }
          }
          .more {
            font-size: 20px;
            font-weight: 500;
            line-height: 0;
            vertical-align: text-top;
          }
          .btnbox {
            background-color: #ffffff;
            position: absolute;
            right: -75px;
            top: 5px;
            .btn-ingrid {
              border: none !important;
              height: 26px;
              font-size: 0.67rem;
              text-align: left;
              padding: 0 0 0 7px;
              &:hover {
                color: var(--primary-main);
                background-color: #ffffff;
              }
            }
          }
          &:hover .btnbox {
            display: flex;
            flex-direction: column;
            box-shadow: 0.5px 0.5px 2px 0 #bfc0ca;
            .btn-outline-primary {
              line-height: 1.5;
              color: #b4b4b4;
              font-weight: 500;
              font-size: 12px;
              padding: 5px 7px;
              &:hover,
              &:focus {
                color: var(--primary-main);
              }
            }
          }
        }
      }

      .card-body {
        padding: 15px 14px 0!important;
        line-height: 20px;
        .cardBody-header{
          font-size: 14px;
          font-weight: 500;
          color: var(--black-medium-text);
        }
        .cardBody-subHeader{
          font-size: 12px;
          color: var(--black-medium-text);
          .dot{
            width: 3px;
            height: 3px;
            top: 0;
          }
          .bodySub-text{
            font-size: 11px;
            color: var(--grey-font);
          }
        }
        .body-text {
          font-size: 8px;
          color: var(--grey-font);
        }

        .card-text {
          font-size: 15px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 188px;
          overflow: hidden;
        }

        .bodySub-text {
          font-size: 12px;
        }
        .suspicious-activity {
          margin-top: 10px;
          .flag-icon {
            margin-top: -1px;
          }
        }
      }

      .card-footer {
        background-color: white;
        border: none !important;
        padding: 5px 14px 11px 14px;
        margin-left: 1px;
        .footer-requestId{
          font-size: 11px;
          color: var(--grey-font);
        }
        .footer-text {
          font-size: 12px;
          font-weight: 500;
          margin-top: 24px;
          margin-bottom: 12px;

          .text-content {
            font-size: 12px;
            font-weight: 500;
            color: #252525;
            border-top: solid 1px var(--grey-border);
            padding-top: 5px;
          }

          .label-left {
            color: #9b9b9b;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }

      &:hover .customCheck input[type='checkbox'] + label {
        display: block;
      }
      &:hover .customCheck {
        display: block;
      }
      &:hover .more-options {
        display: block;
      }
      &:hover .connector {
        display: none;
      }

      .customCheck input[type='checkbox']:checked ~ .connector {
        display: none;
      }

      .customCheck input[type='checkbox']:checked + label,
      .customCheck input[type='checkbox']:checked ~ .more-options {
        display: block;
      }
    }
  }
  .privileged_card_Sessions {
    .card {
      background-color: var(--primary-bg);
      width: 206px;
      height: 230px;
      border-radius: 3px;
      padding: 22px 32px;

      .card-body {
        justify-content: center;
        padding: 0;
        display: contents !important;
        a {
          background-color: var(--white);
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-bottom: 24px;
          margin-left: auto;
          margin-right: auto;
          .icon-size {
            width: 26px;
            height: 28px;
            border-radius: 3px;
            object-fit: contain;
            margin: auto;
            .connector {
              width: 100%;
              object-fit: none;
            }
          }
        }
        .pending_sessions_count {
          width: 150px;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.23;
          letter-spacing: 0.08px;
          text-align: center;
          color: var(--black);
          margin-bottom: 10px;
        }
        .request_history_button {
          width: 128px;
          height: 19px;
          border-radius: 3px;
          border: 0 none;
          background: var(--primary-main);
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.36;
          padding: 0;
          margin-bottom: 20px;
        }
        .latestStatus {
          font-size: 12px;
          line-height: 1.17;
          text-align: center;
          color: var(--black);
          margin-bottom: 2px;
        }
        .new-request {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.17;
          color: var(--primary-main);
          text-align: center;
        }
      }
    }
  }
}
