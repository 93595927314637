.dropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.uploadIconBlock {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  margin: 10px auto;
  padding-top: 30px;
  margin-bottom: 17px;
}
.uploadIcon {
  width: 40px;
  // height: 50px;
  background: url(/ECMv6/assets/images/upload.svg) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0px auto;
  padding-top: 30px;
  position: relative;
  top: -20px;
}
.downloadIcon {
  width: 50px;
  height: 50px;
  background-image: url(/ECMv6/assets/images/Download.svg) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.dropMessage {
  text-align: center;
  color: #cdd5fe;
  font-family: Arial;
  font-size: 20px;
}

.configHeader {
  padding: 64px 0px 0px 0px;

  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.11px;
    color: var(--black);
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: var(--grey-font-input);
    text-align: center;
    margin-bottom: 24px;
  }
}

.configHeaderThemes {
  padding: 0px 0px;
  h4 {
    text-align: center;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.11px;
    color: var(--black);
  }
  p {
    font-size: 14px;
    font-weight: normal;
    color: var(--grey-font-input);
    text-align: center;
    margin-bottom: 24px;
  }
}

.sectionContainer {
  box-shadow: 1px 1px 6px 0 rgba(223, 224, 235, 0.5);
  margin: 0px 0px 0px 0px;
  border-radius: 3px;
  box-shadow: 3px 3px 8px 0 var(--primary-bg);
  background-color: var(--white);

  padding: 24px 24px 24px 24px !important;
  margin-top: -8px;

  h4 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.11px;
    color: var(--black);
    margin-bottom: 6px;
  }
  p {
    font-size: 12px;
    font-weight: normal;
    color: var(--grey-font-input);
    margin-bottom: 16px;
  }
  .jsonEditor {
    margin: 20px 0;
  }
}

.title {
  font-size: 2rem;
  text-align: center !important;
  margin-top: 10%;
  color: #4aa1f3;
  font-weight: bold;
}

.content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  transform: translateY(-100%);
}

:focus {
  outline: none;
}
.csvCodeBlock {
  margin: 0px 0 165px 0px;
  padding: 23px 24px 70px;
  border-radius: 3px;
  box-shadow: 3px 3px 8px 0 #dfe0eb;
  background-color: #ffffff;
  margin-top: -8px;
}

.csvCodeBlock h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.downloadCsv {
  width: 50%;
  margin: 0px auto;
  margin-top: 70px;
  text-align: center;
}
.downloadCsv h4 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
  word-break: break-all;
}
.downloadFile {
  height: 55px;
  margin: 24px 0px;
  padding: 15px 16px;
  border-radius: 6px;
  border: solid 1px #dddddd;
}
.downloadFile .fileIcon {
  float: left;
  background: #f4f4f4;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.downloadFile .fileIcon img {
  width: 24px;
  padding-top: 2px;
  padding-left: 4px;
  opacity: 0.7;
}
.downloadFile .fileName {
  font-size: 14px;
  float: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747474;
  padding-top: 2px;
  padding-left: 10px;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.error {
  color: red;
  padding-bottom: 5px;
}
.informationIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #cdd5fe;
  float: right;
  cursor: pointer;
}
.informationIcon img {
  width: 20px;
  padding-top: 3px;
  padding-left: 4px;
}
.downloadFile .fileDownload {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ebeeff;
  float: right;
  cursor: pointer;
}
.fileDownload svg path {
  fill: red;
}
.downloadFile .closeIcon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #cdd5fe;
  float: right;
  color: #747474;
}
.close {
  position: relative;
  top: 2px;
}
.saveText {
  color: transparent !important;
  z-index: +99;
  line-height: 21px;
  position: relative;
  top: -20px;
}
.downloadFile .fileDownload img {
  width: 24px;
  position: relative;
  padding-left: 2px;
  padding-top: 2px;
}
.dorpZoneTitle {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #242424;
}
.dorpZoneArea {
  margin: 0px auto;
  min-width: 380px;
  min-height: 161px;
  margin: 0 0 16px;
  padding: 16px;
  border-radius: 6px;
  border: 2px #cdd5fe;
  border-style: dashed;
  background-color: #f7f8fc;
  // box-shadow: 3px 3px 8px 0 #dfe0eb;
  cursor: pointer;
}
.dorpZoneArea p {
  font-size: 14px;
}
.dorpZoneArea .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.dorpZoneArea .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.navigationTabs {
  padding-top: 180px;
  list-style: none;
  position: fixed;
  width: 75%;
  overflow: hidden;
  background-color: #f7f8fc;
  margin-top: -98px;
}

.saveButton {
  float: right;
}
.menuList a {
  line-height: 21px;
}
.active {
  color: red;
}

.menuList a.active {
  border-bottom: 3px solid var(--primary-main) !important;
}

.adminHeader {
  background-color: #f7f8fc;
  position: fixed;
  top: 24px;
  width: 71%;
  height: 150px;
  display: table;
  z-index: +9;
}

.noteInfo {
  font-size: 12px;
  color: #747474;
}
.brandHeader {
  clear: both;
  display: block;
}
.brandHeader h6 {
  margin-bottom: 6px !important;
}
.brandHeader p {
  margin-bottom: 16px !important;
}
.textpadding {
  padding-top: 240px;
  margin-left: 0px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
  // box-shadow: 3px 3px 3px 0 #dfe0eb;
  margin-right: 0px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  min-height: 650px;
}
.headingText {
  height: 1px;
  border: solid 1px #dfe0eb;
}
