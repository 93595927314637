.ele-container {
  margin-top: 30px;
  .rolesFound{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-top: 50px;
  }
  .divTable {
    //  padding:4px 14px;
    .dropdown {
      display: none;
    }
    .divTableHead {
      cursor: default;
      color: #a5abb4;
    }
  }
}
