/*list search box */
.list-searchfilter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding-left: 11px;
  border-radius: 3px;
  width: 354px;
  height: 44px;
  .list-searchBox {
    background-color: white;
    padding-left: 0;
    height: 30px;
    width: 200px;
    padding-left: 11px;
    border: 0;
    outline: none;
    font-size: 12px;
    color: #b4b4b4;
    flex: 1;
  }
  .list-close {
    margin-right: 10px;
  }
}
