.status-msg {
  left: 18%;
  position: absolute;
  z-index: 9999999;
  .success-msg {
    border-radius: 3px;
    border: solid 1px #30b661;
    background-color: #e2f0ec;
    font-size: 12px;
    color: #30b661;
    padding: 8px 7px;
    position: fixed;
    min-width: 220px;
    bottom: 20px;
    z-index: 1000;
    .link {
      // margin-left: 10px;
      &:hover {
        color: #30b661;
      }
    }
  }
  .error-msg {
    border-radius: 3px;
    border: solid 1px #f5c6cb;
    background-color: #f8d7da;
    font-size: 12px;
    color: #721c24;
    padding: 8px 7px;
    position: fixed;
    min-width: 220px;
    bottom: 20px;
    z-index: 1000;
    .link {
      // margin-left: 10px;
      &:hover {
        color: #721c24;
      }
    }
  }
}
.backToDetailsPageSection {
  opacity: 0.5;
  padding: 15px;
  height: 15px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--grey-font);
  margin-top: 10px;
  margin-bottom: 10px;
}
.backButton-selection {
  // padding: 15px;
  height: 15px;
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9d9d9d;
}
.entitlement-type-section {
  margin-top: 5px;
}
.system-type-section {
  margin-top: 5px;
}
