.hide {
  display: none;
}
.show {
  display: block;
}
.flex {
  display: flex;
}
/* =============Applied Filetr CSS======== */

.applied_filter_row {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 28;
  background-color: #ffffff;
  border-radius: 3px 3px 0 0;
  border-top: solid 1px var(--grey-border);
  border-left: solid 1px var(--grey-border);
  border-right: solid 1px var(--grey-border);
  border-bottom: 1px solid var(--primary-main);
  color: var(--grey-font);
  font-size: 12px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
  margin-bottom: 1px;
  label {
    margin: 6px 0 0;
    white-space: nowrap;
  }
}
.filter_result {
  display: flex;
}
.fl_applied_list {
  margin: 0 15px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  li {
    border-radius: 3px;
    background-color: var(--primary-bg);
    padding: 6px 10px;
    color: var(--primary-main);
    margin: 0 5px 5px 0;
    white-space: nowrap;
    .remove_icon {
      stroke: #969696;
      margin-left: 10px;
      margin-top: -1px;
      display: inline-block;
      &:hover {
        stroke: var(--primary-main);
      }
    }
  }
}
.fl_btn {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 8px;
  .clear {
    font-weight: 500;
    display: inline-block;
  }
  .show {
    color: #969696;
    margin-right: 40px;
    display: inline-block;
    white-space: nowrap;
    &:hover {
      color: var(--primary-main);
    }
  }
}
.table {
  .dissable {
    color: #b4b4b4;
  }
}
.approve_button {
  height: 36px;
}
.gridFilter {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  z-index: 29;
  background-color: var(--page-bg);
  // padding-bottom: 20px;
  padding-bottom: 5px;
}
.gridFilterArea {
  display: flex;
  justify-content: space-between;
}
.advanceFilterModal {
  z-index: 1100 !important;
}

.actionmargin {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.applymargin {
  margin-left: 21px;
  background-color: #3751ff;
  color: #ffffff !important;
  width: 80px;
  font-size: 12px;
  font-weight: 500;
  padding: 7px 23px;
  border-radius: 3px;
}

.tableCheckBox {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  top: 15px;
}

.tableBodyCheckBox {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  margin-top: 9px;
}
