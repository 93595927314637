.tooltip {
  div {
    visibility: hidden;
  }
  &:hover {
    div {
      visibility: visible;
    }
  }
}
