.team-content-section {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
  margin-bottom: 80px;
  padding-left: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--home-rightpanel-bg);
  font-family: "Rubik", Helvetica, Arial, serif;
  opacity: 1.0;
  .recent-section{
    display: inline-flex;
    text-align: center;
    width: 100px;
    margin-top: 9px;
    .name{
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--home-featuretile-title);    
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;  
      width: 100px;
      margin: 5px auto;
    }
    .status{
      border-radius: 4px;
      background-color: #dae3ff;
      width: 60px;
      margin-left: 18px;
      color: var(--primary-main);
      font-weight: 500;
    }
  }

  .people-icon{
    .approvalIcon{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:#f3f3f3;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      margin-left:-10px;
      border: solid 1px #dfdfdf;
      .textColor{
        color: var(--grey-font);
        font-size: 12px;
      }
    }
  }

  .approvalIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#f3f3f3;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-left:-10px;
    border: solid 1px #dfdfdf;
    .textColor{
      color: var(--grey-font);
      font-size: 12px;
    }
  }
  
  .recent-dropdown{
    position: absolute !important ;
    border-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(122, 122, 122, 0.5);
    background-color: #ffffff;
    width: 26.5%;
  }
  .iconSize {
    font-size: 14px;
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 46px;
    min-width: 46px;
    font-style: normal;
    font-weight: 500;
  }
  .people-count{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: var(--home-main-title);
    padding-left: 22px;
    line-height: 30px;
  }
  .people-carousel{
    padding-left: 22px;
  }
  .no-item {
    max-height: 134px;
  }
  .search-width{
    // max-width: 360px;
    margin: 0 38px 42px 23px;
  }
  .userButton{
    padding-left: 13px;
    padding-right: 20px;
  }
  .manageHover{
    background-color: #ffffff;
    &:hover{
      background-color: #dae3ff;
    }
  }
  .viewAllUsers{
    border-radius: 3px;
    font-weight: normal;
  }
  .people-container{
    font-family: "Rubik", Helvetica, Arial, serif;
    font-stretch: normal;
    font-style: normal;    
    letter-spacing: normal;
    padding-left: 30px;
     &:hover{
      background-color: var(--hover-Bg);
      border-radius: 6px;
      margin-right: 15px;
      padding-right: 22px;
     }
    .people-score {
      text-align: end;
      display: flex;
      align-items: flex-end;
      margin-left: auto;
      button {
        width: 78px;
        height: 28px;
      }
      .span1 {
        font-weight: 500;
        line-height: 1;
        font-size: 14px;
        letter-spacing: normal;
        color: var(--black);
      }
      .span2 {
        font-size: 12px;
        font-weight: normal;
        color: var(--grey-font);
      }
    }
    .people-detail {
      line-height: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;      
      letter-spacing: normal;
      padding-left: 5px !important;
      margin-left: 12px;
      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: var(--black);
        max-width: 230px;
      }
      .username {
        font-size: 12px;
        line-height: 1.17;
        color: var(--home-main-title);
        max-width: 190px;
      }
      .status {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.17;
        color: var(--home-subtitle);
      }
    }
  }
  .people-container  button {
    visibility: hidden;
   }
   .people-container:hover button {
    visibility: visible;
   }
}

.peopleSearchBar{
  position: sticky;
  top: 48px;
  z-index: 11;
  background:  var(--home-rightpanel-bg);
  margin-top: 30px;
}


.dropDownLabel{
  svg{
    fill: #000000;
  }
}

.optionItemText{
  span{
    font-size: 12px;
  }
}

.recentLoading{
  display: flex;
  margin-top: 10px;
  .recentUserLoading{
    margin-right: 20px;
    width: 20rem;
  }
  .recentImageIcon{
    width: 50px;
    height: 50px;
    margin-top: 5px;
    margin-left: 1.4rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    position: relative;
  }
}

.gridLoading {
  display: block;
  width: 90%;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.15);
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  position: relative;
  border-radius: 4px;
}
