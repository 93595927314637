.dynamicAttributeHeader {
  width: 100%;
  font-size: 11px;
  display: flex;
  padding-right: 70px;
  span{
    font-size: 12px;
  }
  .dynamicLabelKey {
    color: #70757a;
    font-size: 11px;
    text-transform: uppercase;
  }
}


.endpointAttribute{
  margin-top:12px;
  h6{
    font-size: 13px;
    color: #000000;
  }
}

.expandDetails{
  .endpointAttribute{
    padding:0;
  }
}
.dynamicColor{
  color: #000000;
}


